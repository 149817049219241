import React from 'react';
import SectionOne from './Section/SectionOne';
import SectionTwo from './Section/SectionTwo';

function ViewParameter({ closeModal,qa_id ,rfn_id,status,datavalue}) {
    const foodDetails= datavalue?.nutrition;
    return (
        <div>
            <div class="modal-header">
                <h5 class="modal-title fw-bold">Parameters</h5>
                <button type="button" class="btn-close" onClick={closeModal} aria-label="Close"></button>
            </div>
            <div className='view-parameter mt-4 container-fluid'>
                <SectionOne foodDetails={foodDetails} />
                <SectionTwo foodDetails={foodDetails?.micro}/>
            </div>
        </div>
    )
}

export default ViewParameter
