import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

//USER APIS

export const VFSlogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }) => {
    try {
      const response = await fetch(`${BASE_URL}user-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error("Failed to log in");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error occurred during login. Please try again.");
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "user/sendPasswordResetEmail",
  async (email, { rejectWithValue }) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ to: email });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}user-forgot-password`,
        requestOptions
      );
      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Network response was not ok");
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async ({ valuedata, newPassword, confirmPassword }, { rejectWithValue }) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      token: valuedata,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}user-reset-password`,
        requestOptions
      );
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (token, { rejectWithValue }) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      token: token,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${BASE_URL}user-logout`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.text();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchResetDataCheck = createAsyncThunk(
  "user/fetchtokenstatus",
  async (valuedata, { rejectWithValue }) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}reset?token=${valuedata}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

// Admin APIS

export const AdminCountDashboard = createAsyncThunk(
  "admin/dashboard",
  async ({token,navigate}, thunkAPI) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-card-count`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data?.data[0];
    } catch (error) {
      return thunkAPI.rejectWithValue("Error fetching data: " + error.message);
    }
  }
);

export const AdminAddUser = createAsyncThunk(
  "admin/adduser",
  async ({ formData, token,navigate }) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      fullName: formData.fullName,
      email: formData.email,
      role: formData.role,
      ph_code: formData.countrycodevalue,
      phoneNo: formData.contactNumber,
      gender: formData.gender,
      dob: formData.dob,
      age: formData.age,
      pincode: formData.zipCode,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      address: formData.address,
      assigned_qa: formData.qa === "Select" ? "" : formData.qa,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-user-enroll-database`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Error fetching data: " + error.message);
    }
  }
);

//admin fetch qa list and country codes
export const fetchCountrycodesandqadetails = createAsyncThunk(
  "admin add edit user/fetchCountrycodesandqadetails",
  async ({ token,navigate }, { rejectWithValue }) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-get-codes-qa`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        const errorResponse = await response.json();
        return rejectWithValue(errorResponse.message || "Failed to fetch data");
      }

      const result = await response.json();
      return result.data; // Adjust according to your API response structure
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const FetchEditUser = createAsyncThunk(
  "admin/fetchedituser",
  async ({ statekey, token,navigate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}admin-get-edit-details/${statekey}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      return response.data.data[0];
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchResearcherCount = createAsyncThunk(
  "admin/fetchResearcherCount",
  async ({token,navigate}, thunkAPI) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-getCount-researcher`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Failed to fetch researcher count");
      }
      const result = await response.json();
      return result.data;
    } catch (error) {
      return thunkAPI(error.message);
    }
  }
);

export const fetchAdminUserDetails = createAsyncThunk(
  "adminUser/fetchDetails",
  async ({ typevalue, _, thunkAPI, token, user_id,navigate }) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      type: typevalue,
      user_id: user_id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-user-details`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "user/updateStatus",
  async ({ token, action, user,navigate }, { rejectWithValue }) => {
    const data = JSON.stringify({
      type: parseInt(action),
      user_id: user,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}admin-user-details`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add the token here
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAdminViewList = createAsyncThunk(
  "data/fetchData",
  async ({ userid, role, token,navigate }, thunkAPI) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}admin-view-list/${userid}/${role}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const AdminpostEditUser = createAsyncThunk(
  "admin/postedituser",
  async ({ formData, token,navigate }, { rejectWithValue }) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`); // Attach the token here

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-edit-userenrolldata`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error.message); // Use rejectWithValue for proper error handling
    }
  }
);

export const AdminExcelDownload = createAsyncThunk(
  "admin/downloadTemplate",
  async ({token,navigate}, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${BASE_URL}admin-download-template`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AdminExcelUpload = createAsyncThunk(
  "admin/uploadExcel",
  async ({ file, userId, token ,navigate}, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const formData = new FormData();
      formData.append("files", file);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}admin-upload/${userId}`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const TaskAllocate = createAsyncThunk(
  "admin/taskAllocate",
  async ({ userId, token,navigate }, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}admin-allocate-tasks/${userId}`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Failed to allocate tasks");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const ReassignQA = createAsyncThunk(
  "admin/reassignqa",
  async ({ token, role, datavalue,navigate }, { rejectWithValue }) => {
    const data = JSON.stringify(datavalue);

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-reallocate-task-${role}`,
        config
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ReassignResearcher = createAsyncThunk(
  "admin/reassignresearcher",
  async (
    { token, rfn_id, old_researcher_id, new_researcher_id, created_by },
    { rejectWithValue }
  ) => {
    const data = JSON.stringify({
      rfn_id,
      old_researcher_id,
      new_researcher_id,
      created_by,
    });

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-reallocate-task-researcher`,
        config
      );
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DownloadApprovedFood = createAsyncThunk(
  "admin/downloadapprovedfood",
  async ({ selectedIds, token }, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      let url = `${BASE_URL}admin-download-approved-food`;
      if (selectedIds.length > 0) {
        const queryParams = new URLSearchParams();
        selectedIds.forEach((id) => queryParams.append("rfn_id", id));
        url += `?${queryParams.toString()}`;
      }

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "approved_food.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();

      return { message: "Download started" };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserNotification = createAsyncThunk(
  "user/fetchUserNotification",
  async ({ token,navigate }, thunkAPI) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}view-user-notification`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Failed to fetch user notifications"
        );
      }

      const data = await response.json();
      return data.data; // Make sure to return only the data part
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateUserNotification = createAsyncThunk(
  "notifications/update",
  async ({ notfy_id, token,navigate }, thunkAPI) => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        notfy_id,
      }),
    };

    const response = await fetch(
      `${BASE_URL}update-user-notification`,
      requestOptions
    );
    if (response.status === 403 || response.statusText === 'Forbidden') {
      localStorage.removeItem("Data");
      navigate("/login")
    }
    const data = await response.text();

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return data;
  }
);

// Researcher APIS

//Researcher dashboard count
export const countresearcherdashboard = createAsyncThunk(
  "researcher/countDashboard",
  async ({  token,navigate }, thunkAPI) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}researcher-total-counts`,
        requestOptions
      );

      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        `Fetching researcher dashboard data failed: ${error.message}`
      );
    }
  }
);

//Researcher dashboard table (to so....)
export const fetchResearchertables = createAsyncThunk(
  "researcher/table",
  async ({ status, token,navigate }, thunkAPI) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add token to headers
        },
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}researcher-list-of-tasks?status=${status}`,
        requestOptions
      );
   if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        `Fetching researcher tables failed: ${error.message}`
      );
    }
  }
);

// fetch integration researcher
export const fetchIngredientSuggestions = createAsyncThunk(
  "researcher/fetchSuggestions",
  async ({ query, token,navigate }, thunkAPI) => {
    try {
      const response = await fetch(
        `${BASE_URL}researcher-search-ingredients?name=${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the headers
          },
        }
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        `Fetching ingredient suggestions failed: ${error.message}`
      );
    }
  }
);

export const uploadFoodDetails = createAsyncThunk(
  "researcher/foodDetails/upload",
  async (
    {
      formData,
      selectedImages,
      statekey,
      status,
      token,
      blobstorage,
      audioname,
      audiomodelurl,
      navigate,
    },
    { rejectWithValue }
  ) => {
    try {
      const wavFile = new File([blobstorage], `${audioname}.wav`, {
        type: "audio/wav",
      });
      const formDatadata = new FormData();

      if (selectedImages) {
        if (Array.isArray(selectedImages) && selectedImages.length > 0) {
          for (const file of selectedImages) {
            if (typeof file === "string") {
              formDatadata.append("file", file);
            } else {
              formDatadata.append("files", file);
            }
          }
        }
      }

      if (blobstorage) {
        formDatadata.append("files", wavFile);
      } else {
        formDatadata.append("audio", audiomodelurl);
      }

      const dataPayload = {
        rfn_id: statekey?.data?.rfn_id,
        status,
        task_date: statekey?.data?.allocated_date,
        food_details: {
          name_of_food: formData.nameoffood,
          portion: formData.portion,
          category_of_food: formData.categoryoffood,
          type_of_triglyceride: formData.triglyceride,
          different_spellings: formData.spellingoffood,
          quantity: formData.quantity,
          food_group: formData.Foodgroup,
          unit: formData.unit,
          nutrient_rich: formData.Nutrientrich,
          country: formData.country,
          processed_food_level: formData.Processed,
          therapepeutic_use_of_food: formData.Therapeutic,
          cuisine: formData.cuisine,
        },
        ingredients_details: {
          added_ingredients: formData.ingredients,
          gi: {
            value: formData.glycaemicIndex,
            reference_for_gi: formData.referenceGI,
          },
          references: formData.referenceFoodIngredients,
        },
        languages: {
          kannada: formData.Kannada,
          hindi: formData.Hindi,
          tamil: formData.Tamil,
          punjabi: formData.Punjabi,
          bengali: formData.Bengali,
          telugu: formData.Telugu,
          odia: formData.Odia,
          gujarati: formData.Gujarati,
          urdu: formData.Urdu,
          marathi: formData.Marathi,
          malayalam: formData.Malayalam,
        },
        comments: formData.comments,
        audio_file_name: audioname,
      };

      // Append JSON payload to FormData
      formDatadata.append("data", JSON.stringify(dataPayload));

      const response = await fetch(
        `${BASE_URL}researcher-add-or-edit-food-details`,
        {
          method: "POST",
          body: formDatadata,
          headers: {
            Authorization: `Bearer ${token}`, // Include token in headers
          },
        }
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error(`Error submitting data: ${response.statusText}`);
      }

      const result = await response.text();
      return result;
    } catch (error) {
      return rejectWithValue(`Uploading food details failed: ${error.message}`);
    }
  }
);

export const ViewfetchFoodDetails = createAsyncThunk(
  "researcher/viewDetails",
  async ({ rfnid, statusvalue, token,navigate }, thunkAPI) => {
    try {
      const response = await fetch(
        `${BASE_URL}researcher-view-food-details?rfn_id=${rfnid}&status=${statusvalue}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include token in headers
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const result = await response.json();
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        `Fetching food details failed: ${error.message}`
      );
    }
  }
);

export const fetchResearcherData = createAsyncThunk(
  "researcher/fetchResearcherData",
  async (userId, thunkAPI) => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}researcher-approved-Totalcounts/${userId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//QA view food
export const ViewQafetchFoodDetails = createAsyncThunk(
  "qa/view deatils",
  async ({ userid, rfnid, statusvalue, token,navigate }, thunkAPI) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${BASE_URL}qa-view-food-details?rfn_id=${rfnid}&status=${statusvalue}`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        `Fetching researcher dashboard data failed: ${error.message}`
      );
    }
  }
);

export const fetchQaTasksTable = createAsyncThunk(
  "qa/tables",
  async ({ status, token,navigate }) => {
    const response = await fetch(
      `${BASE_URL}qa-list-of-tasks?status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 403 || response.statusText === 'Forbidden') {
      localStorage.removeItem("Data");
      navigate("/login")
    }
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  }
);

export const getQaNumber = createAsyncThunk(
  "number/fetchnumber",
  async ({token,navigate }) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      };
      const response = await fetch(
        `${BASE_URL}qa-total-counts`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.data;
      // return await fetchQa(qa_id, status);
    } catch (error) {
      throw new Error(`Fetching QA Rework tasks failed: ${error.message}`);
    }
  }
);

export const uploadQaFoodDetails = createAsyncThunk(
  "qa foodDetails/upload",
  async (
    {
      formData,
      selectedImages,
      userData,
      statekey,
      status,
      token,
      blobstorage,
      audioname,
      audiomodelurl,
      navigate,
    },
    { rejectWithValue }
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const wavFile = new File([blobstorage], `${audioname}.wav`, {
        type: "audio/wav",
      });
      const formDatadata = new FormData();
      // for (const file of selectedImages) {
      //   formDatadata.append("files", file);
      // }
      if (selectedImages) {
        if (Array.isArray(selectedImages) && selectedImages.length > 0) {
          for (const file of selectedImages) {
            if (typeof file === "string") {
              formDatadata.append("file", file);
            } else {
              formDatadata.append("files", file);
            }
          }
        }
      }

      if (blobstorage) {
        formDatadata.append("files", wavFile);
      } else {
        formDatadata.append("audio", audiomodelurl);
      }
      const dataPayload = {
        rfn_id: statekey?.data?.rfn_id,
        user_id: statekey?.data?.researcher_id,
        status: status,
        qa_id: userData?.userId,
        task_date: statekey?.data?.recieved_date,
        food_details: {
          name_of_food: formData.nameoffood,
          portion: formData.portion,
          category_of_food: formData.categoryoffood,
          type_of_triglyceride: formData.triglyceride,
          different_spellings: formData.spellingoffood,
          quantity: formData.quantity,
          food_group: formData.Foodgroup,
          unit: formData.unit,
          nutrient_rich: formData.Nutrientrich,
          country: formData.country,
          processed_food_level: formData.Processed,
          therapepeutic_use_of_food: formData.Therapeutic,
          cuisine: formData.cuisine,
        },
        ingredients_details: {
          added_ingredients: formData.ingredients,
          gi: {
            value: formData.glycaemicIndex,
            reference_for_gi: formData.referenceGI,
          },
          references: formData.referenceFoodIngredients,
        },
        languages: {
          kannada: formData.Kannada,
          hindi: formData.Hindi,
          tamil: formData.Tamil,
          punjabi: formData.Punjabi,
          bengali: formData.Bengali,
          telugu: formData.Telugu,
          odia: formData.Odia,
          gujarati: formData.Gujarati,
          urdu: formData.Urdu,
          marathi: formData.Marathi,
          malayalam: formData.Malayalam,
        },
        // comment: {
        comments: formData.comment, 
        audio_file_name:audioname,
        // }
      };

      formDatadata.append("data", JSON.stringify(dataPayload));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formDatadata,
      };

      const response = await fetch(
        `${BASE_URL}qa-review-food-details`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }

      if (!response.ok) {
        throw new Error("Error submitting data");
      }

      const result = await response.text();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ViewAdminfetchFoodDetails = createAsyncThunk(
  "admin/viewDetails",
  async ({ rfnid, token,navigate }, thunkAPI) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${BASE_URL}admin-view-fooddetails?rfn_id=${rfnid}`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        `Fetching researcher dashboard data failed: ${error.message}`
      );
    }
  }
);

export const fetchUploadedIngredients = createAsyncThunk(
  "ingredients/fetchUploaded",
  async ({ rfn_id, token,navigate }, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}researcher-admin-uploaded-ingredients?rfn_id=${rfn_id}`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error("Failed to fetch ingredients");
      }

      const result = await response.text();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//admin zipcode
export const fetchZipcode = createAsyncThunk(
  "zipcode/fetchDetails",
  async ({ token, zipcode ,navigate}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${BASE_URL}admin-zipcode?zipcode=${zipcode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Ensure proper token usage
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      return response.data; // Ensure data structure matches expected format
    } catch (error) {
      return error.response.data;
    }
  }
);


//userdeatils
export const fetchUserInfodetails = createAsyncThunk(
  'user/fetchUserInfo',
  async ({ email, token,navigate }, thunkAPI) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append(
        'Authorization',
        `Bearer ${token}`
      );

      const raw = JSON.stringify({
        email: email,
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      const response = await fetch(
        `${BASE_URL}user-fetch-userinfo`,
        requestOptions
      );
      if (response.status === 403 || response.statusText === 'Forbidden') {
        localStorage.removeItem("Data");
        navigate("/login")
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        `Fetching user info failed: ${error.message}`
      );
    }
  }
);




//admin pendeing task
export const fetchAdminPendingFood = createAsyncThunk(
  'food/fetchPendingFood',
  async ({token,navigate}) => {
    const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);
myHeaders.append("Content-Type", "application/json");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

    const response = await fetch(`${BASE_URL}admin-getPendingFood`, requestOptions);
    if (response.status === 403 || response.statusText === 'Forbidden') {
      localStorage.removeItem("Data");
      navigate("/login")
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data;
  }
);