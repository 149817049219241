import React from 'react';
import '../../../Style/Qa.scss';

function SectionOne({ foodDetails }) {

    function getNovaCategory(nova) {
        switch (nova) {
            case "Unprocessed":
                return 1;
            case "Processed culinary ingredients":
                return 2;
            case "Processed":
                return 3;
            case "Ultra processed":
                return 4;
            default:
                return "-";
        }
    }
    return (
        <div className='section-one-para'>
            <>
                <div className='Nutrition'>
                    <h5 className='fw-bolder'>Nutrition Score</h5>
                    <div className='shadow rounded'>
                        <div className='row px-3'>
                            <div className='col-md  border-end-column'>
                                <span className=''>NOVA</span>
                                <span className=' text-end'>
                                    {foodDetails?.score?.nova !== undefined
                                        ? getNovaCategory(foodDetails?.score?.nova)
                                        : '-'}
                                </span>
                            </div>
                            <div className='col-md   border-end-column'>
                                <span className=''>Glycaemic Index</span>
                                <span className=' text-end'>
                                    {foodDetails?.score?.gi !== undefined
                                        ? foodDetails.score.gi
                                        : '-'}
                                </span>
                            </div>
                            <div className='col-md   border-end-column'>
                                <span className=''>Spoons of Sugar</span>
                                <span className=' text-end'>
                                    {foodDetails?.score?.spoons_of_sugar !== undefined
                                        ? Number(foodDetails.score.spoons_of_sugar).toFixed(2)
                                        : '-'}
                                </span>
                            </div>
                            <div className='col-md   border-end-column'>
                                <span className=''>Satiety Score(FF)</span>
                                <span className=' text-end'>
                                    {foodDetails?.score?.fullness_factor !== undefined
                                        ? Number(foodDetails.score.fullness_factor).toFixed(2)
                                        : '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Macronutrients'>
                    <h5 className='fw-bolder my-3'>Macronutrients</h5>
                    <div className='shadow rounded'>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span className=''>Moisture</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.other?.moisture?.value !== undefined
                                        ? Number(foodDetails.macro.other.moisture.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.other?.moisture?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Protein</span>
                                <span className='text-end '>
                                    {foodDetails?.macro?.other?.protein?.value !== undefined
                                        ? Number(foodDetails.macro.other.protein.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.other?.protein?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Ash</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.other?.ash?.value !== undefined
                                        ? Number(foodDetails.macro.other.ash.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.other?.ash?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Total Fat</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.other?.total_fat?.value !== undefined
                                        ? Number(foodDetails.macro.other.total_fat.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.other?.total_fat?.unit || ''}
                                </span>
                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md  border-end-column'>
                                <span className=''>Total Dietary Fibre</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.fibre?.total_dietary_fibre?.value !== undefined
                                        ? Number(foodDetails?.macro?.fibre?.total_dietary_fibre?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.fibre?.total_dietary_fibre?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span className=''>Insoluble Dietary Fibre</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.fibre?.insoluble_dietary_fibre?.value !== undefined
                                        ? Number(foodDetails.macro.fibre.insoluble_dietary_fibre.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.fibre?.insoluble_dietary_fibre?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span className=''>Soluble Dietary Fibre</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.fibre?.soluble_dietary_fibre?.value !== undefined
                                        ? Number(foodDetails.macro.fibre.soluble_dietary_fibre.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.fibre?.soluble_dietary_fibre?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span className=''>Carbohydrates</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.other?.carbohydrates?.value !== undefined
                                        ? Number(foodDetails.macro.other.carbohydrates.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.other?.carbohydrates?.unit || ''}
                                </span>
                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span className=''>Energy</span>
                                <span className=' text-end'>
                                    {foodDetails?.macro?.other?.energy?.value !== undefined
                                        ? Number(foodDetails.macro.other.energy.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.macro?.other?.energy?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'></div>
                            <div className='col-md border-end-column'></div>
                            <div className='col-md border-end-column'></div>
                        </div>
                    </div>
                </div>


                <div className='Micronutrients'>
                    <h5 className='fw-bolder my-3'>Micronutrients</h5>
                    <h6 className='fw-bold mb-3'>Water Soluble Vitamins</h6>
                    <div className='shadow rounded'>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span className=''>Thiamine (B1)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.thiamine_b1?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.thiamine_b1.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.thiamine_b1?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Riboflavin (B2)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.riboflavin_b2?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.riboflavin_b2.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.riboflavin_b2?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Niacin (B3)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.niacin_b3?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.niacin_b3.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.niacin_b3?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span className=''>Pantothenic Acid (B5)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.pantothenic_acid_b5?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.pantothenic_acid_b5.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.pantothenic_acid_b5?.unit || ''}
                                </span>
                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span className=''>Total B6</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.total_b6?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.total_b6.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.total_b6?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span className=''>Biotin (B7)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.biotin_b7?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.biotin_b7.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.biotin_b7?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span className=''>Total Folates (B9)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.total_folates_b9?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.total_folates_b9.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.total_folates_b9?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span className=''>Total Ascorbic Acid (Vitamin C)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.water_soluble_vitamins?.total_ascorbic_acid?.value !== undefined
                                        ? Number(foodDetails.micro.water_soluble_vitamins.total_ascorbic_acid.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.water_soluble_vitamins?.total_ascorbic_acid?.unit || ''}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='Fat'>
                    <h6 className='fw-bold my-4'>Fat Soluble Vitamins</h6>
                    <div className='shadow rounded'>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span className=''>Ergocalciferol (D2)</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.ergocalciferol_d2?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.ergocalciferol_d2.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.ergocalciferol_d2?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Alpha Tocopherols</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.alpha_tocopherols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.alpha_tocopherols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.alpha_tocopherols?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Beta Tocopherols</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.beta_tocopherols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.beta_tocopherols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.beta_tocopherols?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Gamma Tocopherols</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.gamma_tocopherols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.gamma_tocopherols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.gamma_tocopherols?.unit || ''}
                                </span>
                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span className=''>Delta Tocopherols</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.delta_tocopherols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.delta_tocopherols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.delta_tocopherols?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Alpha Tocotrienols</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.alpha_tocotrienols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.alpha_tocotrienols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.alpha_tocotrienols?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Beta Tocotrienols</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.beta_tocotrienols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.beta_tocotrienols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.beta_tocotrienols?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span className=''>Delta Tocotrienols</span>
                                <span className=' text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.delta_tocotrienols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.delta_tocotrienols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.delta_tocotrienols?.unit || ''}
                                </span>
                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span >Gamma Tocotrienols</span>
                                <span className='text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.gamma_tocotrienols?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.gamma_tocotrienols.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.gamma_tocotrienols?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span >Alpha Tocopherol Equivalent</span>
                                <span className='text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.alpha_tocopherol_equivalent?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.alpha_tocopherol_equivalent.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.alpha_tocopherol_equivalent?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span >Phylloquinones (K1)</span>
                                <span className='text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.phylloquinones_k1?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.phylloquinones_k1.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.phylloquinones_k1?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span>Retinol</span>
                                <span className='text-end'>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.retinol?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.retinol.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.retinol?.unit || ''}
                                </span>
                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span >Cholecalciferol (D3)</span>
                                <span>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.cholecalciferol_d3?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.cholecalciferol_d3.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.cholecalciferol_d3?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span >25-OH-D3</span>
                                <span>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.['25_oh_d3']?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins['25_oh_d3'].value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.['25_oh_d3']?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span >Menaquinones (K2)</span>
                                <span>
                                    {foodDetails?.micro?.fat_soluble_vitamins?.menaquinones_k2?.value !== undefined
                                        ? Number(foodDetails.micro.fat_soluble_vitamins.menaquinones_k2.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.fat_soluble_vitamins?.menaquinones_k2?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'></div>
                        </div>
                    </div>
                </div>


                <div className='Carotenoids'>
                    <h6 className='fw-bold my-4'>Carotenoids</h6>
                    <div className='shadow rounded'>
                        <div className='row px-3'>
                            <div className='col-md border-end-column '>
                                <span>Lutein</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.lutein?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.lutein.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.lutein?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span>Zeaxanthin</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.zeaxanthin?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.zeaxanthin.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.zeaxanthin?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span>Lycopene</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.lycopene?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.lycopene.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.lycopene?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span>Beta Cryptoxanthin</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.beta_cryptoxanthin?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.beta_cryptoxanthin.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.beta_cryptoxanthin?.unit || ''}
                                </span>
                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span>Gamma Carotene</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.gamma_carotene?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.gamma_carotene.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.gamma_carotene?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span>Alpha Carotene</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.alpha_carotene?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.alpha_carotene.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.alpha_carotene?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md border-end-column'>
                                <span>Beta Carotene</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.beta_carotene?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.beta_carotene.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.beta_carotene?.unit || ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span>Total Carotenoids</span>
                                <span >
                                    {foodDetails?.micro?.carotenoids?.total_carotenoids?.value !== undefined
                                        ? Number(foodDetails.micro.carotenoids.total_carotenoids.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.carotenoids?.total_carotenoids?.unit || ''}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Minerals'>
                    <h6 className='fw-bold my-4'>Minerals and Trace elements</h6>
                    <div className='shadow rounded'>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span>Aluminium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.aluminium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.aluminium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.aluminium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.aluminium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Arsenic</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.arsenic?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.arsenic?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.arsenic?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.arsenic?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Cadmium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.cadmium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.cadmium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.cadmium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.cadmium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md  border-end-column'>
                                <span>Calcium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.calcium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.calcium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.calcium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.calcium?.unit
                                        : ''}
                                </span>

                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span>Chromium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.chromium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.chromium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.chromium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.chromium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Cobalt</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.cobalt?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.cobalt?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.cobalt?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.cobalt?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Copper</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.copper?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.copper?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.copper?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.copper?.unit
                                        : ''}
                                </span>
                            </div>
                            <div className='col-md  border-end-column'>
                                <span>Iron</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.iron?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.iron?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.iron?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.iron?.unit
                                        : ''}
                                </span>

                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span>Lead</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.lead?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.lead?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.lead?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.lead?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Lithium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.lithium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.lithium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.lithium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.lithium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Magnesium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.magnesium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.magnesium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.magnesium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.magnesium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md   border-end-column'>
                                <span>Manganese</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.manganese?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.manganese?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.manganese?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.manganese?.unit
                                        : ''}
                                </span>

                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span>Mercury</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.mercury?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.mercury?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.mercury?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.mercury?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Molybdenum</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.molybdenum?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.molybdenum?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.molybdenum?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.molybdenum?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Nickel</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.nickel?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.nickel?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.nickel?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.nickel?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md  border-end-column'>
                                <span>Phosphorus</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.phosphorus?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.phosphorus?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.phosphorus?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.phosphorus?.unit
                                        : ''}
                                </span>

                            </div>
                        </div>
                        <div className='row px-3'>
                            <div className='col-md border-end-column'>
                                <span>Potassium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.potassium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.potassium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.potassium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.potassium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Selenium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.selenium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.selenium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.selenium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.selenium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md border-end-column'>
                                <span>Sodium</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.sodium?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.sodium?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.sodium?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.sodium?.unit
                                        : ''}
                                </span>

                            </div>
                            <div className='col-md  border-end-column'>
                                <span>Zinc</span>
                                <span>
                                    {foodDetails?.micro?.minerals_and_trace_elements?.zinc?.value !== undefined
                                        ? Number(foodDetails?.micro?.minerals_and_trace_elements?.zinc?.value).toFixed(2)
                                        : '-'}
                                    {foodDetails?.micro?.minerals_and_trace_elements?.zinc?.unit !== undefined
                                        ? foodDetails?.micro?.minerals_and_trace_elements?.zinc?.unit
                                        : ''}
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default SectionOne
