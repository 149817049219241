import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import AdminDashboard from './Components/AdminDashboard/AdminDashboard';
import HomeScreen from './Components/HomeScreen/HomeScreen';
import QaDashboard from './Components/QaDashboard/QaDashboard';
import ResearcherDashboard from './Components/ResearcherDashboard/ResearcherDashboard';
import CheckYourEmail from "./Components/UserModel/CheckYourMail/CheckYourMail";
import ForgotPassword from "./Components/UserModel/ForgetPassword/ForgetPassword";
import Login from './Components/UserModel/Login/Login';
import ResetPassword from "./Components/UserModel/ResetPassword/ResetPassword";
import ResetSuccess from "./Components/UserModel/ResetSuccess/ResetSuccess";
import Header from './Layout/Header/Header';
import AdimnExcel from './Page/Admin/AdimnExcel/AdimnExcel';
import ViewAdminQa from "./Page/Admin/AdminNumQa/ViewAdminQa/ViewAdminQa";
import ViewAdminRecipe from './Page/Admin/AdminNumRecipe/ViewAdminRecipe/ViewAdminRecipe';
import ViewAdminResearcher from "./Page/Admin/AdminNumResearchers/ViewAdminResearcher/ViewAdminResearcher";
import AddUser from './Page/Admin/User/AddUser/AddUser';
import EditUser from './Page/Admin/User/EditUser/EditUser';
import QaViewApproved from "./Page/Qa/QaRecApproved/QaViewApproved/QaViewApproved";
import QaReviewRecipe from "./Page/Qa/QaRecReview/QaReviewRecipe/QaReviewRecipe";
import QaViewSentRework from "./Page/Qa/QaSentRework/QaViewSentRework/QaViewSentRework";
import ResContinue from './Page/Research/ResearchInProgress/ResContinue/ResContinue';
import ResReadyToView from './Page/Research/ResearchReadyToReview/ResReadyToView/ResReadyToView';
import ResReworkEdit from './Page/Research/ResearchRework/ResReworkEdit/ResReworkEdit';
import ResAddDetails from './Page/Research/ResearchToDo/ResAddDetails/ResAddDetails';
import ViewParameter from "./Page/ViewParameter/ViewParameter";
import QaReviewRework from "./Page/Qa/QaReworkReview/QaReviewRework/QaReviewRework";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/checkyouremail" element={<CheckYourEmail />} />
          <Route path="/reset-password/:valuedata" element={<ResetPassword />} />
          <Route path="/reset-password-success" element={<ResetSuccess />} />
          <Route path="/vfs/*" element={<AuthenticatedRoutes />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}
const AuthenticatedRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const data = localStorage.getItem("Data");
    if (!data) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="border-app-nav" >
      <Header />
      <div className="border-app-body">
        <Routes>
          
          <Route path="/add-user/:datavalue" element={<AddUser />} />
          <Route path="/edit-user/:datavalue" element={<EditUser />} />
          <Route path="/qa-dashboard" element={<QaDashboard />} />
          <Route path="/research-newdetails/:datavalue" element={<ResAddDetails />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/edit-user" element={<EditUser />} />
          <Route path="/view-admin-recipe" element={<ViewAdminRecipe />} />
          <Route path="/admin-excel" element={<AdimnExcel />} />
          <Route path="/researcher-dashboard" element={<ResearcherDashboard />} />
          <Route path="/res-continue/:datavalue" element={<ResContinue />} />
          <Route path="/res-reworkEdit/:datavalue" element={<ResReworkEdit />} />
          <Route path="/res-readytoview/:datavalue" element={<ResReadyToView />} />
          <Route path="/viewpara/:datavalue" element={<ViewParameter />} />
          <Route path="/adminview-res/:datavalue" element={<ViewAdminResearcher />} />
          <Route path="/viewadmin-qa/:datavalue" element={<ViewAdminQa />} />
          <Route path="/qaviewapprove/:datavalue" element={<QaViewApproved />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/qa-sentrework-view/:datavalue" element={<QaViewSentRework />} />
          <Route path="/qa-review-recipe/:datavalue" element={<QaReviewRecipe />} />
          <Route path="/qa-rework-recipe-review/:datavalue" element={<QaReviewRework />} />
          <Route path="adminviewapprove/:datavalue" element={<ViewAdminRecipe/>} />

        </Routes>
      </div>
    </div>
  );
};

export default App;

