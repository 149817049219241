import React from 'react'

function SectionTwo({ foodDetails }) {
  return (
    <div>

      <div className='section-two-para'>

        <>
          <div className='Starch'>
            <h6 className='fw-bold my-4'>Starch and Individual Sugars</h6>
            <div className='shadow rounded'>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Total Available CHO</span>
                  <span>
                    {foodDetails?.starch_and_individual_sugars?.total_available_cho?.value !== undefined
                      ? Number(foodDetails?.starch_and_individual_sugars?.total_available_cho?.value).toFixed(2)
                      : '-'}
                    {foodDetails?.starch_and_individual_sugars?.total_available_cho?.unit}
                  </span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Total Starch</span>
                  <span>
                    {foodDetails?.starch_and_individual_sugars?.total_starch?.value !== undefined
                      ? foodDetails?.starch_and_individual_sugars?.total_starch?.value.toFixed(2)
                      : '-'}
                    {foodDetails?.starch_and_individual_sugars?.total_starch?.unit}
                  </span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Fructose</span>
                  <span>
                    {foodDetails?.starch_and_individual_sugars?.fructose?.value !== undefined
                      ? foodDetails?.starch_and_individual_sugars?.fructose?.value.toFixed(2)
                      : '-'}
                    {foodDetails?.starch_and_individual_sugars?.fructose?.unit}
                  </span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Glucose</span>
                  <span>
                    {foodDetails?.starch_and_individual_sugars?.glucose?.value !== undefined
                      ? foodDetails?.starch_and_individual_sugars?.glucose?.value.toFixed(2)
                      : '-'}
                    {foodDetails?.starch_and_individual_sugars?.glucose?.unit}
                  </span>
                </div>
              </div>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Sucrose</span>
                  <span>
                    {foodDetails?.starch_and_individual_sugars?.sucrose?.value !== undefined
                      ? foodDetails?.starch_and_individual_sugars?.sucrose?.value.toFixed(2)
                      : '-'}
                    {foodDetails?.starch_and_individual_sugars?.sucrose?.unit}
                  </span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Maltose</span>
                  <span>
                    {foodDetails?.starch_and_individual_sugars?.maltose?.value !== undefined
                      ? foodDetails?.starch_and_individual_sugars?.maltose?.value.toFixed(2)
                      : '-'}
                    {foodDetails?.starch_and_individual_sugars?.maltose?.unit}
                  </span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Total Free Sugars</span>
                  <span>
                    {foodDetails?.starch_and_individual_sugars?.total_free_sugars?.value !== undefined
                      ? foodDetails?.starch_and_individual_sugars?.total_free_sugars.value.toFixed(2)
                      : '-'}
                    {foodDetails?.starch_and_individual_sugars?.total_free_sugars?.unit}
                  </span>
                </div>
                <div className='col-md  border-end-column'>
                </div>
              </div>
            </div>
          </div>


          <div className='Fatty'>
            <h6 className='fw-bold my-4'>Fatty Acid Profile</h6>
            <div className='shadow rounded'>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Eicosaenoic</span>
                  <span>{foodDetails?.fatty_acid_profile?.eicosaenoic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.eicosaenoic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.eicosaenoic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Erucic</span>
                  <span>{foodDetails?.fatty_acid_profile?.erucic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.erucic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.erucic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Nervonic</span>
                  <span>{foodDetails?.fatty_acid_profile?.nervonic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.nervonic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.nervonic?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Linoleic</span>
                  <span>{foodDetails?.fatty_acid_profile?.linoleic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.linoleic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.linoleic?.unit}</span>
                </div>
              </div>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Eicosadienoic</span>
                  <span>{foodDetails?.fatty_acid_profile?.eicosadienoic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.eicosadienoic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.eicosadienoic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Alpha Linoleic</span>
                  <span>{foodDetails?.fatty_acid_profile?.alpha_linoleic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.alpha_linoleic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.alpha_linoleic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Arachidonic</span>
                  <span>{foodDetails?.fatty_acid_profile?.arachidonic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.arachidonic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.arachidonic?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Total Saturated Fatty Acids</span>
                  <span>{foodDetails?.fatty_acid_profile?.total_saturated_fatty_acids?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.total_saturated_fatty_acids?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.total_saturated_fatty_acids?.unit}</span>
                </div>
              </div>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Total Mono Unsaturated
                    Fatty Acids</span>
                  <span>{foodDetails?.fatty_acid_profile?.total_mono_unsaturated_fatty_acids?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.total_mono_unsaturated_fatty_acids?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.total_mono_unsaturated_fatty_acids?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Total Poly Unsaturated
                    Fatty Acids</span>
                  <span>{foodDetails?.fatty_acid_profile?.total_poly_unsaturated_fatty_acids?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.total_poly_unsaturated_fatty_acids?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.total_poly_unsaturated_fatty_acids?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Capric</span>
                  <span>{foodDetails?.fatty_acid_profile?.capric?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.capric?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.capric?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Lauric</span>
                  <span>{foodDetails?.fatty_acid_profile?.lauric?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.lauric?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.lauric?.unit}</span>
                </div>
              </div>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Myristic</span>
                  <span>{foodDetails?.fatty_acid_profile?.myristic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.myristic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.myristic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Palmitic</span>
                  <span>{foodDetails?.fatty_acid_profile?.palmitic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.palmitic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.palmitic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Stearic</span>
                  <span>{foodDetails?.fatty_acid_profile?.stearic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.stearic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.stearic?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Arachidic</span>
                  <span>{foodDetails?.fatty_acid_profile?.arachidic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.arachidic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.arachidic?.unit}</span>
                </div>
              </div>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Behenic</span>
                  <span>{foodDetails?.fatty_acid_profile?.behenic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.behenic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.behenic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Lignoceric</span>
                  <span>{foodDetails?.fatty_acid_profile?.lignoceric?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.lignoceric?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.lignoceric?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Myristoleic</span>
                  <span>{foodDetails?.fatty_acid_profile?.myristic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.myristic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.myristic?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Palmitoleic</span>
                  <span>{foodDetails?.fatty_acid_profile?.palmitic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.palmitic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.palmitic?.unit}</span>
                </div>
              </div>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Oleic</span>
                  <span>{foodDetails?.fatty_acid_profile?.oleic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.oleic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.oleic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Undecanoic</span>
                  <span>{foodDetails?.fatty_acid_profile?.undecanoic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.undecanoic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.undecanoic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Pentadecanoic</span>
                  <span>{foodDetails?.fatty_acid_profile?.pentadecanoic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.pentadecanoic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.pentadecanoic?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Eicosatrienoic</span>
                  <span>{foodDetails?.fatty_acid_profile?.eicosatrienoic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.eicosatrienoic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.eicosatrienoic?.unit}</span>
                </div>
              </div>
              <div className='row px-3'>
                <div className='col-md border-end-column'>
                  <span>Eicosapentaenoic</span>
                  <span>{foodDetails?.fatty_acid_profile?.eicosa_pentadecanoic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.eicosa_pentadecanoic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.eicosa_pentadecanoic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Docosahexaenoic</span>
                  <span>{foodDetails?.fatty_acid_profile?.docosa_hexaenoic?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.docosa_hexaenoic?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.docosa_hexaenoic?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Cholesterol</span>
                  <span>{foodDetails?.fatty_acid_profile?.cholesterol?.value !== undefined
                    ? foodDetails?.fatty_acid_profile?.cholesterol?.value.toFixed(2)
                    : '-'}{foodDetails?.fatty_acid_profile?.cholesterol?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                </div>
              </div>
            </div>
          </div>

          <div className='Amino'>
            <h6 className='fw-bold my-4'>Amino Acid profile</h6>
            <div className=' shadow rounded'>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Histidine</span>
                  <span>{foodDetails?.amino_acid_profile?.histidine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.histidine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.histidine?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Isoleucine</span>
                  <span>{foodDetails?.amino_acid_profile?.isoleucine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.isoleucine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.isoleucine?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Leucine</span>
                  <span>{foodDetails?.amino_acid_profile?.leucine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.leucine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.leucine?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Lysine</span>
                  <span>{foodDetails?.amino_acid_profile?.lysine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.lysine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.lysine?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Methionine</span>
                  <span>{foodDetails?.amino_acid_profile?.methionine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.methionine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.methionine?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Cystine</span>
                  <span>{foodDetails?.amino_acid_profile?.cystine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.cystine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.cystine?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Phenylalanine</span>
                  <span>{foodDetails?.amino_acid_profile?.phenylalanine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.phenylalanine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.phenylalanine?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Threonine</span>
                  <span>{foodDetails?.amino_acid_profile?.threonine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.threonine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.threonine?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Tryptophan</span>
                  <span>{foodDetails?.amino_acid_profile?.tryptophan?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.tryptophan.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.tryptophan?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Valine</span>
                  <span>{foodDetails?.amino_acid_profile?.valine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.valine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.valine?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Alanine</span>
                  <span>{foodDetails?.amino_acid_profile?.alanine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.alanine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.alanine?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Arginine</span>
                  <span>{foodDetails?.amino_acid_profile?.arginine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.arginine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.arginine?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Aspartic Acid</span>
                  <span>{foodDetails?.amino_acid_profile?.aspartic_acid?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.aspartic_acid.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.aspartic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Glutamic Acid</span>
                  <span>{foodDetails?.amino_acid_profile?.glutamic_acid?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.glutamic_acid.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.glutamic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Glycine</span>
                  <span>{foodDetails?.amino_acid_profile?.glycine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.glycine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.glycine?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Proline</span>
                  <span>{foodDetails?.amino_acid_profile?.proline?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.proline.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.proline?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Serine</span>
                  <span>{foodDetails?.amino_acid_profile?.serine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.serine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.serine?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Tyrosine</span>
                  <span>{foodDetails?.amino_acid_profile?.tyrosine?.value !== undefined
                    ? foodDetails?.amino_acid_profile?.tyrosine.value.toFixed(2)
                    : '-'}{foodDetails?.amino_acid_profile?.tyrosine?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                </div>
                <div className='col-md  border-end-column'>
                </div>
              </div>
            </div>
          </div>

          <div className='Organic'>
            <h6 className='fw-bold my-4'>Organic Acids</h6>
            <div className=' shadow rounded'>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Total Oxalate</span>
                  <span>{foodDetails?.organic_acids?.total_oxalate?.value !== undefined
                    ? foodDetails?.organic_acids?.total_oxalate?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.total_oxalate?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Soluble Oxalate</span>
                  <span>{foodDetails?.organic_acids?.soluble_oxalate?.value !== undefined
                    ? foodDetails?.organic_acids?.soluble_oxalate?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.soluble_oxalate?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Insoluble Oxalate</span>
                  <span>{foodDetails?.organic_acids?.insoluble_oxalate?.value !== undefined
                    ? foodDetails?.organic_acids?.insoluble_oxalate?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.insoluble_oxalate?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Cis-Aconitic Acid</span>
                  <span>{foodDetails?.organic_acids?.cis_aconitic_acid?.value !== undefined
                    ? foodDetails?.organic_acids?.cis_aconitic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.cis_aconitic_acid?.unit}</span>
                </div>
              </div>

              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Citric Acid</span>
                  <span>{foodDetails?.organic_acids?.citric_acid?.value !== undefined
                    ? foodDetails?.organic_acids?.citric_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.citric_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Fumaric Acid</span>
                  <span>{foodDetails?.organic_acids?.fumaric_acid?.value !== undefined
                    ? foodDetails?.organic_acids?.fumaric_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.fumaric_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Mallic Acid</span>
                  <span>{foodDetails?.organic_acids?.mallic_acid?.value !== undefined
                    ? foodDetails?.organic_acids?.mallic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.mallic_acid?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Quinic Acid</span>
                  <span>{foodDetails?.organic_acids?.quinic_acid?.value !== undefined
                    ? foodDetails?.organic_acids?.quinic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.quinic_acid?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Succinic Acid</span>
                  <span>{foodDetails?.organic_acids?.succinic_acid?.value !== undefined
                    ? foodDetails?.organic_acids?.succinic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.succinic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Tartaric Acid</span>
                  <span>{foodDetails?.organic_acids?.tartaric_acid?.value !== undefined
                    ? foodDetails?.organic_acids?.tartaric_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.organic_acids?.tartaric_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                </div>
                <div className='col-md  border-end-column'>
                </div>
              </div>
            </div>
          </div>

          <div className='Polyphenols'>
            <h6 className='fw-bold my-4'>Polyphenols</h6>
            <div className=' shadow rounded'>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>3,4-Dihydroxy Benzoic Acid</span>
                  <span className='col-md text-end'>
                    {foodDetails?.polyphenols?.['3_4_dihydroxybenzoic_acid']?.value !== undefined
                      ? foodDetails?.polyphenols['3_4_dihydroxybenzoic_acid']?.value.toFixed(2)
                      : '-'}
                    {foodDetails?.polyphenols?.['3_4_dihydroxybenzoic_acid']?.unit}
                  </span>
                </div>
                <div className='col-md border-end-column'>
                  <span>3 - Hydroxybenzaldehyde</span>
                  <span className='col-md text-end'>
                    {foodDetails?.polyphenols?.['3_hydroxybenzaldehyde']?.value !== undefined
                      ? foodDetails?.polyphenols['3_hydroxybenzaldehyde']?.value.toFixed(2)
                      : '-'} {foodDetails?.polyphenols?.['3_hydroxybenzaldehyde']?.unit}
                  </span>

                </div>
                <div className='col-md border-end-column'>
                  <span>Protocatechuic Acid</span>
                  <span>{foodDetails?.polyphenols?.protocatechuic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.protocatechuic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.protocatechuic_acid?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Vanillic Acid</span>
                  <span>{foodDetails?.polyphenols?.vanillic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.vanillic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.vanillic_acid?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Gallic Acid</span>
                  <span>{foodDetails?.polyphenols?.gallic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.gallic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.gallic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Cinnamic Acid</span>
                  <span>{foodDetails?.polyphenols?.cinnamic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.cinnamic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.cinnamic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>O-Coumaric Acid</span>
                  <span>{foodDetails?.polyphenols?.o_coumaric_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.o_coumaric_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.o_coumaric_acid?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>P-Coumaric Acid</span>
                  <span>{foodDetails?.polyphenols?.p_coumaric_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.p_coumaric_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.p_coumaric_acid?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Caffeic Acid</span>
                  <span>{foodDetails?.polyphenols?.caffeic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.caffeic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.caffeic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Chlorogenic Acid</span>
                  <span>{foodDetails?.polyphenols?.chlorogenic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.chlorogenic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.chlorogenic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Ferullic Acid</span>
                  <span>{foodDetails?.polyphenols?.ferullic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.ferullic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.ferullic_acid?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Apigenin</span>
                  <span>{foodDetails?.polyphenols?.apigenin?.value !== undefined
                    ? foodDetails?.polyphenols?.apigenin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.apigenin?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Apigenin-6-C-Gluoside</span>
                  <span>{foodDetails?.polyphenols?.apigenin_6_c_gluoside?.value !== undefined
                    ? foodDetails?.polyphenols?.apigenin_6_c_gluoside?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.apigenin_6_c_gluoside?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Apigenin-7-O-
                    Neohesperidoside</span>
                  <span>{foodDetails?.polyphenols?.apigenin_7_o_neohesperidoside?.value !== undefined
                    ? foodDetails?.polyphenols?.apigenin_7_o_neohesperidoside?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.apigenin_7_o_neohesperidoside?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Luteolin</span>
                  <span>{foodDetails?.polyphenols?.luteolin?.value !== undefined
                    ? foodDetails?.polyphenols?.luteolin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.luteolin?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Kaempferol</span>
                  <span>{foodDetails?.polyphenols?.kaempferol?.value !== undefined
                    ? foodDetails?.polyphenols?.kaempferol?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.kaempferol?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Quercetin</span>
                  <span>{foodDetails?.polyphenols?.quercetin?.value !== undefined
                    ? foodDetails?.polyphenols?.quercetin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.quercetin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Quercetin-3-Beta-D-Glucoside</span>
                  <span>{foodDetails?.polyphenols?.quercetin_3_beta_d_glucoside?.value !== undefined
                    ? foodDetails?.polyphenols?.quercetin_3_beta_d_glucoside?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.quercetin_3_beta_d_glucoside?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Quercetin-3-O-Rutinoside</span>
                  <span>{foodDetails?.polyphenols?.quercetin_3_o_rutinoside?.value !== undefined
                    ? foodDetails?.polyphenols?.quercetin_3_o_rutinoside?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.quercetin_3_o_rutinoside?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Querecetin-3-Beta-Galactoside</span>
                  <span>{foodDetails?.polyphenols?.querecetin_3_beta_galactoside?.value !== undefined
                    ? foodDetails?.polyphenols?.querecetin_3_beta_galactoside?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.querecetin_3_beta_galactoside?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Isorhamnetin</span>
                  <span>{foodDetails?.polyphenols?.isorhamnetin?.value !== undefined
                    ? foodDetails?.polyphenols?.isorhamnetin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.isorhamnetin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Myricetin</span>
                  <span>{foodDetails?.polyphenols?.myricetin?.value !== undefined
                    ? foodDetails?.polyphenols?.myricetin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.myricetin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Reseveratrol</span>
                  <span>{foodDetails?.polyphenols?.reseveratrol?.value !== undefined
                    ? foodDetails?.polyphenols?.reseveratrol?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.reseveratrol?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Hesperetin</span>
                  <span>{foodDetails?.polyphenols?.hesperetin?.value !== undefined
                    ? foodDetails?.polyphenols?.hesperetin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.hesperetin?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Naringenin</span>
                  <span>{foodDetails?.polyphenols?.naringenin?.value !== undefined
                    ? foodDetails?.polyphenols?.naringenin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.naringenin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Hesperdin</span>
                  <span>{foodDetails?.polyphenols?.hesperdin?.value !== undefined
                    ? foodDetails?.polyphenols?.hesperdin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.hesperdin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Daidzein</span>
                  <span>{foodDetails?.polyphenols?.daidzein?.value !== undefined
                    ? foodDetails?.polyphenols?.daidzein?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.daidzein?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Genistein</span>
                  <span>{foodDetails?.polyphenols?.genistein?.value !== undefined
                    ? foodDetails?.polyphenols?.genistein?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.genistein?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Epicatechin</span>
                  <span>{foodDetails?.polyphenols?.epicatechin?.value !== undefined
                    ? foodDetails?.polyphenols?.epicatechin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.epicatechin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Epigallo Catechin</span>
                  <span>{foodDetails?.polyphenols?.epigallo_catechin?.value !== undefined
                    ? foodDetails?.polyphenols?.epigallo_catechin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.epigallo_catechin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Epigallo Catechin
                    3-Gallate</span>
                  <span>{foodDetails?.polyphenols?.epigallo_catechin_3_gallate?.value !== undefined
                    ? foodDetails?.polyphenols?.epigallo_catechin_3_gallate?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.epigallo_catechin_3_gallate?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Catechin</span>
                  <span>{foodDetails?.polyphenols?.catechin?.value !== undefined
                    ? foodDetails?.polyphenols?.catechin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.catechin?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Gallocatechin Gallate</span>
                  <span>{foodDetails?.polyphenols?.gallocatechin_gallate?.value !== undefined
                    ? foodDetails?.polyphenols?.gallocatechin_gallate?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.gallocatechin_gallate?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Gallo Catechin</span>
                  <span>{foodDetails?.polyphenols?.gallo_catechin?.value !== undefined
                    ? foodDetails?.polyphenols?.gallo_catechin?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.gallo_catechin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Syringic Acid</span>
                  <span>{foodDetails?.polyphenols?.syringic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.syringic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.syringic_acid?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Sinapinic Acid</span>
                  <span>{foodDetails?.polyphenols?.sinapinic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.sinapinic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.sinapinic_acid?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Ellagic Acid</span>
                  <span>{foodDetails?.polyphenols?.ellagic_acid?.value !== undefined
                    ? foodDetails?.polyphenols?.ellagic_acid?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.ellagic_acid?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Total Polyphenols</span>
                  <span>{foodDetails?.polyphenols?.total_polyphenols?.value !== undefined
                    ? foodDetails?.polyphenols?.total_polyphenols?.value.toFixed(2)
                    : '-'}{foodDetails?.polyphenols?.total_polyphenols?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                </div>
                <div className='col-md  border-end-column'>
                </div>
              </div>
            </div>
          </div>

          <div className='Oligosaccharides mb-4'>
            <h6 className='fw-bold my-4'>Oligosaccharides Phytosterols Phytates and Saponins</h6>
            <div className=' shadow rounded'>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Raffinose Oligosaccharides</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.raffinose_oligosaccharides?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.raffinose_oligosaccharides?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.raffinose_oligosaccharides?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Stachyose Oligosaccharides</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.stachyose_oligosaccharides?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.stachyose_oligosaccharides?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.stigmasterol_phytosterols?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Verbascose Oligosaccharides</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.verbascose_oligosaccharides?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.verbascose_oligosaccharides?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.verbascose_oligosaccharides?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Ajugose Oligosaccahrides</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.ajugose_oligosaccahrides?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.ajugose_oligosaccahrides?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.ajugose_oligosaccahrides?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Campesterol Phytosterols</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.campesterol_phytosterols?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.campesterol_phytosterols?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.campesterol_phytosterols?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Stigmasterol Phytosterols</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.stigmasterol_phytosterols?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.stigmasterol_phytosterols?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.stigmasterol_phytosterols?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                  <span>Beta-Sitosterol</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.beta_sitosterol?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.beta_sitosterol?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.beta_sitosterol?.unit}</span>
                </div>
                <div className='col-md  border-end-column'>
                  <span>Phytate</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.phytate?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.phytate?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.phytate?.unit}</span>
                </div>
              </div>
              <div className='row px-3 '>
                <div className='col-md border-end-column'>
                  <span>Total Saponin</span>
                  <span>{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.total_saponin?.value !== undefined
                    ? foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.total_saponin?.value.toFixed(2)
                    : '-'}{foodDetails?.oligosaccharides_phytosterols_phytates_and_saponins?.total_saponin?.unit}</span>
                </div>
                <div className='col-md border-end-column'>
                </div>
                <div className='col-md border-end-column'>
                </div>
                <div className='col-md  border-end-column'>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      {/* } */}
    </div>
  )
}

export default SectionTwo
